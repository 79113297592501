@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #1aac83;
  --error: #e7195a;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}

header {
  background: #fff;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header a {
  color: #333;
  text-decoration: none;
}

.text-centered {
  text-align: center;
}
.text-right {
  text-align: right;
}

.pages {
  box-sizing: border-box;
  margin: 0 auto;
  height: 100vh;
}

/* new member form */

label,
input {
  display: block;
}

input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
div.stage-background {
  display: flex;
  gap: 1rem;
}

div.stage-backsplash {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
div.stage-scrollable {
  overflow-y: scroll;
}

div.home-backsplash-red {
  background-color: #d2042d;
}

img.backsplash-img {
  height: 100%;
}
img.home-backsplash-img {
  width: 100%;
}

div.home-about {
  width: 75%;
}

/* sidebar */

aside.sidebar-container {
  align-items: center;
  background: #fff;
  display: flex;
  height: 100vh;
}
div.sidebar-background {
  background-color: #fff;
  width: 80px;
  height: 80px;
  position: absolute;
}

button.sidebar-btn {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 1;
}

div.sidebar-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  /* width: 250px; */
  /* height: 100vh; */
}

/* preview */

div.preview-container {
  position: relative;
}

div.stage-preview {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
    0 0 16px hsl(0deg 0% 0% / 0.075);
}

div.stage-preview > img.preview-img {
  width: 100%;
}

button.btn-close {
  position: absolute;
  top: 4px;
  right: 4px;
}
div.main-close-btn-container {
  position: relative;
}

div.stage-promo {
  font-size: 5em;
  width: 65%;
  overflow-y: scroll;
}

/* Article(s) */
article {
  margin: 4px;
}

article h1 {
  font-family: "Times New Roman", Times, serif;
  font-size: 3.75em;
}
